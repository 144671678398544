<template>
  <el-row v-if="editable">
    <el-image v-if="url && !isUpdated"
              style="width: 146px; height: 146px;margin-right: 5px"
              :src="url"
              :preview-src-list="[url]"></el-image>
    <el-upload
        ref="uploadRef"
        list-type="picture-card"
        accept=".jpeg,.png,.jpg"
        :file-list="imgVal"
        :action="action"
        :limit="limit"
        :on-preview="(file) => handlePictureCardPreview(file)"
        :on-remove="$emit('handleRemove')"
        :on-success="(response) => emit('handleSuccess', response)"
        :before-upload="beforeImgUpload"
        :headers="headerObj()"
        :on-error="handleError"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">
        <em class="LinkText">{{ uploadText }}</em><br/>
        {{ uploadInfo }}
      </div>
    </el-upload>
    <el-dialog v-model="imgVisible">
      <el-image fit="full" :src="imgUrl" alt=""/>
    </el-dialog>
  </el-row>
  <el-row v-else>
    <el-image
        style="width: 300px; height: 200px;"
        :src="url"
        :preview-src-list="[url]"
        :initial-index="1"/>
  </el-row>
</template>

<script setup>
import {ElMessage} from "element-plus";
import {computed, getCurrentInstance, ref} from "vue";

const props = defineProps({
  action: {required: true},
  editable: {required: true},
  url: {required: true},
  limit: {default: 1},
  uploadText: {default: ''},
  uploadInfo: {default: '支持jpeg、png、jpg格式'},
  showExample: {default: false},
  previewSrcList: {default: ''},
  span: {default: 12}
})

console.log(props)
const uploadRef = ref(null)
const emit = defineEmits(['handleRemove', 'handleSuccess'])
const imgVal = ref([])
const imgUrl = ref(props.url)
const imgVisible = ref(false)
const isUpdated = ref(false)

const {proxy} = getCurrentInstance()

const beforeImgUpload = file => {
  isUpdated.value = true
  let img = file.name.substring(file.name.lastIndexOf('.') + 1)

  if (['jpg', 'png', 'jpeg', 'JPG', 'PNG', 'JPEG'].indexOf(img) < 0) {
    ElMessage.error('仅支持上传图片！')
  }
}

const headerObj = () => {
  return {Authorization: proxy.$store.getters.userInfo.token}
}

// 处理图片预览功能
const handlePictureCardPreview = (file) => {
  if (file.response) {
    if (file.response.show_path || file.response.path1) {
      imgUrl.value = file.response.show_path ? file.response.show_path : file.response.path1
    } else {
      imgUrl.value = file.response.path ? file.response.path : ''
    }
  } else {
    imgUrl.value = ''
  }
  imgVisible.value = true
}

const handleError = (params) => {
  console.log(params)
}

const refreshData = () => {
  isUpdated.value = false
  if (uploadRef.value) uploadRef.value.clearFiles()
}

defineExpose({refreshData})
</script>

<style lang="less" scoped>
:deep(.el-upload),
:deep(.el-upload-dragger) {
  min-width: 300px !important;
  min-height: 200px !important;

  .el-upload__text {
    position: relative;
    bottom: 33px;
    line-height: 1.5;
  }

  em {
    font-style: normal;
  }
}

:deep(.el-upload--picture-card i) {
  font-size: 67px;
}

</style>