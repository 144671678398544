<template>
  <el-card v-loading="$store.getters.isLoading">
    <template #header>
      <el-row justify="space-between">
        <span class="SubHead">钱包充值</span>
        <el-link
          target="_blank"
          type="primary"
          class="LinkText"
          :href="`${base.admin_url}/pdfjs/web/viewer.html?file=/static/index/pdf/payment.pdf`"
          >查看YinoLink易诺支持的付款方式
        </el-link>
      </el-row>
    </template>
    <el-form ref="formRef" label-width="100px" :model="form" :rules="rules">
      <el-form-item label="可用金额" required>
        <span :class="balance < 0 ? 'dangerColor' : 'LinkText'"> $ {{ filters.thousands(balance) }} </span>
      </el-form-item>
      <el-form-item label="充值金额" prop="dollar">
        <el-input-number
          size="medium"
          v-model="form.dollar"
          :precision="2"
          :min="minDollar"
          :max="maxDollar"
          :step="100"
        />
        <span style="margin-left: 5px">美金</span>
        <span style="color: var(--el-color-danger); font-size: 12px; margin-left: 20px">
          *最低充值{{ minDollar }}美金
        </span>
        <span style="color: #8c8c8c; font-size: 14px; margin-left: 200px">
          可前往<el-link
            class="LinkText"
            style="display: contents"
            type="primary"
            href="/recharge_list"
            :underline="false"
          >
            付款记录 </el-link
          >查看钱包充值明细
        </span>
      </el-form-item>
      <el-form-item prop="type">
        <el-radio-group v-model="form.type">
          <el-row class="payment-radio" v-for="payment in payments" :key="payment.type">
            <el-col :span="19">
              <el-radio style="width: 100%" border :label="payment.type">
                <el-row :gutter="20" align="middle">
                  <el-col :span="5" class="payment-img">
                    <img v-if="payment.img" :src="payment.img" />
                    <i v-else-if="payment.icon" :class="'payment-icon ' + payment.icon" />
                    <span class="payment-label">{{ payment.label }}</span>
                  </el-col>
                  <el-col :span="19" class="describe">
                    <span v-html="payment.describe"></span>
                  </el-col>
                </el-row>
              </el-radio>
            </el-col>
          </el-row>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-row style="line-height: 14px"> <i style="color: #f56c6c">* </i>温馨提示： </el-row>
        <el-row class="Text">
          如支付遇到问题，请将截图上传至
          <el-link type="primary" class="LinkText" href="/feedback" :underline="false">问题反馈</el-link>
        </el-row>
      </el-form-item>
      <el-form-item prop="agree">
        <el-row align="middle" :class="form.agree ? '' : 'agreeTipBorder'">
          <el-checkbox v-model="form.agree" label="" style="margin-right: 5px"></el-checkbox>
          <div class="agreeBox">
            <span class="Text"
              >我已了解：充值的款项只可用于YinoLink易诺所属的广告账户消费，如需退款，YinoLink易诺只支持美金退回至银行账号</span
            >
          </div>
        </el-row>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :disabled="!form.agree" @click="goPay">前往付款页面</el-button>
      </el-form-item>
    </el-form>
  </el-card>
  <verify
    v-if="'verify' === dialogType"
    :is-special="false"
    :type="form.type"
    :dollar="form.dollar"
    @close="dialogType = ''"
    @update=";(dialogType = 'confirm'), (confirmData = $event)"
  />
  <confirm-pay
    v-if="'confirm' === dialogType"
    :dialog-type="dialogContentType"
    :dialog-content="dialogContent"
    :payment-data="form"
    :confirm-data="confirmData"
    @close="dialogType = ''"
  />
  <upload-voucher v-if="'voucher' === dialogType" @close="dialogType = ''" />
  <upload-three v-if="'three' === dialogType" @close="dialogType = ''" />
</template>

<script setup>
import base from '@/request/base'
import { getCurrentInstance, h, onBeforeUnmount, onMounted, ref } from 'vue'
import filters from '@/base/filters/base'
import payments from '@/components/payments'
import api from '@/request/api/finance'
import { ElMessage, ElMessageBox } from 'element-plus'
import Verify from '@/views/finance/base/Verify'
import ConfirmPay from '@/views/finance/recharge/ConfirmPay'
import init from '@/views/finance/recharge/base'
import UploadVoucher from '@/views/finance/recharge/UploadVoucher'
import UploadThree from '@/views/finance/recharge/UploadThree'
import { loadAirwallex } from 'airwallex-payment-elements'

const { proxy } = getCurrentInstance()

const formRef = ref(null)
const form = ref({
  dollar: 100,
  type: '支付宝',
  agree: false,
})
const rules = {
  dollar: [
    { required: true, message: '请输入充值金额', trigger: 'blur' },
    {
      pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
      message: '请输入正确的充值金额，最多保留2位小数',
      trigger: 'blur',
    },
    {
      validator: (rule, val, callback) => {
        if (val < minDollar.value) {
          callback(new Error(`充值金额不能低于${minDollar.value}美元`))
        } else {
          callback()
        }
      },
      trigger: 'blur',
    },
  ],
}

const minDollar = ref(1000)
const maxDollar = ref(0)

const dialogType = ref('')
const dialogContentType = ref('')
const dialogContent = ref({})

const balance = ref('--')
const realType = ref(false)

const confirmData = ref({})

const { refreshStatus, initConfirmData } = init()

const getRechargePay = async () => {
  const res = await api.rechargePay(form.value.type, form.value.dollar)
  confirmData.value = initConfirmData(form.value.type, res)
}

const goPay = async () => {
  const result = await formRef.value.validate()
  if (true !== result) return
  if (realType.value && ['支付宝', '支付宝1', '微信', '银联'].indexOf(form.value.type) >= 0)
    return (dialogType.value = 'verify')
  if (['支付宝', '支付宝1', '微信', '银联', '连连支付'].indexOf(form.value.type) >= 0) {
    await getRechargePay()
    dialogType.value = 'confirm'
    dialogContentType.value = '连连支付' === form.value.type ? 'alert' : ''
    if ('连连支付' === form.value.type) window.open(confirmData.value.url)
    return
  }

  if (['PingPong', '万里汇'].indexOf(form.value.type) >= 0) {
    confirmData.value = {}
    dialogType.value = 'confirm'
    dialogContentType.value = 'alert'
    dialogContent.value = {
      title: '提示',
      content:
        '<div>付款成功后将充值到您在YinoLink易诺的钱包余额中。</div>需注意：若付款的账号未与YinoLink易诺签署第三方付款协议，后续需要补签。',
      confirmText: '继续支付',
      cancelText: '取消支付',
      handleSuccess: async () => {
        dialogType.value = ''
        await getRechargePay()
        window.open(confirmData.value.url)
        dialogType.value = 'confirm'
        dialogContentType.value = 'alert'
        dialogContent.value = {}
      },
    }
    return
  }

  if ('空中云汇' === form.value.type) {
    const res = await api.rechargePay(form.value.type, form.value.dollar)
    if (!res.airwallex) return ElMessage.error('唤起支付失败')
    confirmData.value = initConfirmData(form.value.type, res)
    dialogType.value = 'confirm'
    return
  }

  dialogType.value = 'voucher'
}

onBeforeUnmount(() => {
  ElMessage.closeAll()
})

onMounted(async () => {
  const res = await api.recharge().catch((res) => {
    ElMessage.closeAll()
    return res
  })

  if (res.code === 100) {
    return ElMessage.error({
      message: res.msg,
      duration: 1000,
      onClose() {
        proxy.$router.push('/homepage')
      },
    })
  }

  if (res.data && res.data.account_type && 1 == res.data.account_type) {
    ElMessageBox.confirm('您当前并没有广告账号，无法充值', '提示', {
      confirmButtonText: '确定',
      showClose: false,
      showCancelButton: false,
      closeOnClickModal: false,
      closeOnPressEscape: false,
    }).finally(() => {
      proxy.$router.push('/QbitRecharge')
    })
  } else {
    ElMessage({
      showClose: true,
      duration: 0,
      type: 'warning',
      customClass: 'three-notice',
      iconClass: 'el-icon-warning-outline',
      dangerouslyUseHTMLString: true,
      message: h('div', [
        h('div', { class: 'title' }, '提示'),
        h('span', [
          '如果您的付款账号信息与您的企业认证签约信息不一致，请先签署第三方代付协议再进行付款。',
          h(
            'a',
            {
              class: 'el-link el-link--primary',
              onClick: () => {
                dialogType.value = 'three'
              },
            },
            h('span', { class: 'el-link__inner LinkText' }, '点击前往')
          ),
        ]),
      ]),

      // `如果您的付款账号信息与您的企业认证签约信息不一致，请先签署第三方代付协议再进行付款。<el-link type="primary" :underline="false" >点击前往</el-link>`
    })
    balance.value = res.data.balance
    if (res.data.real_type) realType.value = true
    minDollar.value = parseInt(res.data.config.less_amount)
    maxDollar.value = parseInt(res.data.config.more_amount)
  }
})

const handleUpdate = (type, data) => {
  if ('verify' === type) {
    dialogType.value = 'confirm'
    confirmData.value = data
  }
}

const width = ref(window.innerWidth)

onMounted(() => {
  window.onresize = () => {
    width.value = window.innerWidth
  }
})
</script>

<style lang="less" scoped>
:deep(.payment-radio) {
  padding-bottom: 20px;

  .el-radio.is-bordered {
    height: auto;
  }

  .el-radio__label {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px 0 15px 20px;
  }

  img {
    width: 40px;
    height: 40px;
  }

  .payment-img {
    display: flex;
  }

  .payment-label {
    font-size: 16px;
    margin-left: 10px;
    color: #000000;
  }

  .el-radio__label .el-row {
    width: 100%;
  }

  .el-radio__label .describe {
    padding-right: 0 !important;
  }

  .el-radio__label .describe span {
    white-space: break-spaces;
    font-size: 14px;
    color: #595959 !important;
    font-weight: normal !important;
  }

  .payment-icon {
    font-size: 40px;
    font-weight: 400 !important;
    color: #000;
  }
}

:deep(.agreeTipBorder) {
  border: 1px dashed #f56c6c;
  padding-left: 10px;
}
</style>

<style>
.three-notice {
  background-color: #e6f7ff !important;
  border: 1px solid #91d5ff !important;
  line-height: 1.5 !important;
  color: #606266;
  padding: 15px 15px 15px 24px !important;
  z-index: 2000 !important;
  min-width: 60% !important;
}

.three-notice .el-icon-warning-outline {
  color: #1890ff !important;
  font-size: 24px;
}

.three-notice .title {
  font-size: 16px !important;
  color: #303133 !important;
}

.three-notice .el-message__closeBtn {
  top: 25px !important;
}

.three-notice span {
  font-size: 14px;
}

.three-notice .el-link {
  font-size: 14px !important;
  display: contents !important;
}

.qbit-top-banner {
  margin-top: -16px;
  margin-bottom: 16px;
  min-height: 70px;
}

.qbit-top-banner img {
  width: 100%;
  cursor: pointer;
}
</style>
