import alipay from '@/assets/iconImg/payment/alipay.png'
import weixinpay from '@/assets/iconImg/payment/weixinpay.png'
import lainlian from '@/assets/iconImg/payment/lainlian.png'
import pingpang from '@/assets/iconImg/payment/pingpang.png'
import unionpay from '@/assets/iconImg/payment/unionpay.png'
import worldfirst from '@/assets/iconImg/payment/worldfirst.png'
import airwallex from '@/assets/iconImg/payment/airwallex.jpeg'

export default [
  {
    type: '支付宝', label: '支付宝',
    img: alipay,
    describe: '实名认证后，可直接通过个人支付宝扫码付款，手续费0.8%。每笔金额不得高于$5000.00',
  },
  // {
  //   type: '支付宝1', label: '支付宝1',
  //   img: alipay,
  //   describe: '实名认证后，可直接通过个人支付宝扫码付款，手续费0.65%',
  // },
  {
    type: '微信', label: '微信',
    img: weixinpay,
    describe: '实名认证后，可直接通过个人微信扫码付款，手续费0.8%。每笔金额不得高于$5000.00',
  },
  {
    type: '空中云汇', label: '空中云汇',
    img: airwallex,
    describe: `已有空中云汇账户，可直接登录后使用余额转账，手续费根据金额阶梯收取，最高不超过0.3%`,
  },
  {
    type: '银联', label: '银联',
    img: unionpay,
    describe: '实名认证后，可直接通过银联转账付款，借记卡手续费0.8%。每笔限额以付款银行为准',
  },
  {
    type: '连连支付', label: '连连支付',
    img: lainlian,
    describe: '关联连连账户后，可直接通过连连付款。连连余额支付仅收取店铺提现手续费（具体以连连收银台页面展示为准），人民币网银支付手续费0.3%',
  },
  {
    type: 'PingPong', label: 'PingPong支付',
    img: pingpang,
    describe: '已有PingPong账户，可直接登录后付款。若无PingPong账户，点击后可进行注册',
  },
  {
    type: '万里汇', label: '万里汇',
    img: worldfirst,
    describe: '关联万里汇账户后，可直接通过万里汇账户付款，资金实时到账，手续费0.3%',
  },
  {
    type: '其他', label: '其他',
    icon: 'iconfont icon-qita2',
    describe: '除上述付款方式外，可通过上传打款凭证进行付款。财务审核到账后，会充值到钱包余额中',
  },
]